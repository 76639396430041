export const randomPredictions = [
  { date: "oRMbQXkIV", Prediction: 97 },
  { date: "wWPgshDVZx", Prediction: 119 },
  { date: "Bweoae", Prediction: 107 },
  { date: "EKtCMGCyH", Prediction: 138 },
  { date: "PMfThWfnNy", Prediction: 16 },
  { date: "oymMhkzHw", Prediction: 92 },
  { date: "vPnPEpf", Prediction: 16 },
  { date: "XafbKl", Prediction: 48 },
  { date: "CgvYOy", Prediction: 95 },
  { date: "DgfJaLqvgk", Prediction: 23 },
];

export const randomDetailedPredictions = [
  {
    userId: "uXQojY",
    userPhoto: "",
    value: "58",
    timestamp: { seconds: 1712658722, nanoseconds: 250598392 },
    id: 0,
  },
  {
    userId: "pCiAlFuKe",
    userPhoto: "",
    value: "156",
    timestamp: { seconds: 1712671522, nanoseconds: 236459522 },
    id: 1,
  },
  {
    userId: "skkinkjaDV",
    userPhoto: "",
    value: "115",
    timestamp: { seconds: 1712672355, nanoseconds: 863069436 },
    id: 2,
  },
  {
    userId: "gCKrFd",
    userPhoto: "",
    value: "137",
    timestamp: { seconds: 1712663886, nanoseconds: 891116930 },
    id: 3,
  },
  {
    userId: "eReBUnnSo",
    userPhoto: "",
    value: "50",
    timestamp: { seconds: 1712665268, nanoseconds: 459241949 },
    id: 4,
  },
  {
    userId: "OChQA",
    userPhoto: "",
    value: "102",
    timestamp: { seconds: 1712663630, nanoseconds: 882164800 },
    id: 5,
  },
  {
    userId: "dQtwqzKt",
    userPhoto: "",
    value: "43",
    timestamp: { seconds: 1712659022, nanoseconds: 887975767 },
    id: 6,
  },
  {
    userId: "AMLimNLFm",
    userPhoto: "",
    value: "27",
    timestamp: { seconds: 1712666411, nanoseconds: 621661023 },
    id: 7,
  },
  {
    userId: "IxegQ",
    userPhoto: "",
    value: "54",
    timestamp: { seconds: 1712660807, nanoseconds: 168201864 },
    id: 8,
  },
  {
    userId: "faAAEwKbOE",
    userPhoto: "",
    value: "19",
    timestamp: { seconds: 1712673247, nanoseconds: 545921258 },
    id: 9,
  },
];
