import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import Button from "./Button";

export const SignIn = () => {
  const provider = new TwitterAuthProvider();
  const isMobile = window.innerWidth < 768;
  const auth = getAuth();
  const signIn = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;
        const user = result.user;
      })
      .catch((error) => {
        console.error(error);
      });

  return (
    <div class="relative inline-flex  group">
      <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt mt-4"></div>
      <a
        onClick={signIn}
        title="Get quote now"
        class="relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
        role="button"
      >
        {isMobile
          ? "Sign In with X / Twitter"
          : "Single-Click Sign In with X / Twitter"}
      </a>
    </div>
  );
};
