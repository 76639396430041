import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button, TextInput } from "@tremor/react";

export const AnnounceResults = ({ onResultSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState("");

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleSubmit = () => {
    onResultSubmit(result);
    closeModal();
    setResult("");
  };

  return (
    <>
      <Button
        onClick={openModal}
        className="mr-4 bg-amber-500 text-white border-none hover:bg-amber-600 hover:border-none"
      >
        Announce Results
      </Button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Enter Poll Result
                  </Dialog.Title>

                  <div className="mt-2">
                    <TextInput
                      type="text"
                      className="w-full"
                      placeholder="Result"
                      value={result}
                      onChange={(e) => setResult(e.target.value)}
                    />
                  </div>
                  <p className="text-sm text-gray-500 mt-2">
                    Please note: Submitting the result is final and cannot be
                    changed. Ensure the accuracy of the result before
                    submission.
                  </p>

                  <div className="mt-4">
                    <Button onClick={closeModal} variant="secondary">
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      className="bg-blue-500 text-white hover:bg-blue-600 ml-2"
                    >
                      Submit
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
