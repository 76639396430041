const Avatar = ({ imageUrl, username }) => {
  const isMobile = window.innerWidth < 768;
  const hideOnMobile = isMobile;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!hideOnMobile && (
        <>
          <img
            src={imageUrl}
            alt="avatar"
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: 8,
            }}
          />
          <div style={{ marginRight: username ? 16 : 0, fontWeight: 600 }}>
            {username}
          </div>
        </>
      )}
    </div>
  );
};

export default Avatar;
