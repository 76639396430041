import { signInWithPopup, TwitterAuthProvider, getAuth } from "firebase/auth";
import { Icon } from "@tremor/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Button from "./components/Button";
import { CreatePoll } from "./pages/CreatePoll";
import { db } from "./firebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import useSetState from "./hooks/useSetState";
import { LivePoll } from "./pages/LivePoll";
import { useEffect } from "react";
import { Landing } from "./pages/Landing";
import { Header } from "./components/Header";
import { Card } from "@tremor/react";
import { PollStatusIndicator } from "./components/PollStatusIndicator";
import { store } from "./hooks/store";
import { Category } from "./components/Category";
import TimeAgo from "./components/TimeAgo";
import { Loading } from "./components/Loading";
import { checkout } from "./helpers/createSubscription";
import { CategoryTag } from "./components/CategoryTag";

function App() {
  const provider = new TwitterAuthProvider();
  const auth = getAuth();
  const navigate = useNavigate();
  const setIsProUser = store((state) => state.setIsProUser);
  const isProUser = store((state) => state.isProUser);

  const [
    { userPolls, currentUser, title, createdBy, selectedCategory },
    setState,
  ] = useSetState({
    userPolls: [],
    currentUser: null,
    title: "",
    createdBy: "",
    selectedCategory: "all",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ [name]: value });
  };

  const handleCategoryChange = (category) => {
    setState({ selectedCategory: category });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setState({ currentUser: user });
      } else {
        setState({ currentUser: null });
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchUserPolls = async () => {
    if (auth.currentUser) {
      let q = query(
        collection(db, "polls"),
        orderBy("created", "desc"),
        limit(20)
      );

      if (title.trim() !== "") {
        q = query(q, where("title", "==", title));
      }

      if (createdBy.trim() !== "") {
        q = query(q, where("createdByName", "==", createdBy));
      }

      // Add condition for selectedCategory
      if (selectedCategory.trim() !== "all") {
        q = query(q, where("category", "==", selectedCategory));
      }

      try {
        const querySnapshot = await getDocs(q);
        const polls = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setState({ userPolls: polls });
      } catch (error) {
        console.error("Error fetching polls: ", error);
      }
    }
  };

  const signIn = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;
        const user = result.user;
      })
      .catch((error) => {
        console.error(error);
      });

  useEffect(() => {
    if (currentUser) {
      fetchUserPolls();
    }
  }, [currentUser, title, createdBy, selectedCategory]);

  useEffect(() => {
    if (currentUser) {
      const isProUser =
        currentUser?.reloadUserInfo?.customAttributes?.includes("pro");
      if (isProUser) {
        setIsProUser(isProUser);
      }
    }
  }, [currentUser]);

  if (!auth._isInitialized) {
    return <Loading />;
  }

  if (!currentUser) {
    return <Landing user={currentUser} />;
  }

  return (
    <div className="">
      <Header noNav user={currentUser} />
      <Routes>
        <Route
          path="/"
          element={
            <div className="flex items-center flex-col sm:mt-0 md:mt-8">
              <div class="relative inline-flex  group hidden md:block">
                <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt mt-4"></div>
                <a
                  onClick={() => navigate("/create")}
                  title="Get quote now"
                  class="relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  role="button"
                >
                  Create Poll
                </a>
              </div>

              <div className="mt-4">
                <a
                  className="text-xs "
                  href="https://x.com/mrShomski"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Questions or feature requests? X / Twitter{" "}
                  <span className="font-bold">@mrShomski</span>
                </a>
              </div>
              <div className="flex flex-col items-center mt-2 md:mt-8 w-full px-4 sm:px-6 lg:px-8">
                <div className="w-full max-w-screen-lg mx-auto">
                  <p className="font-bold">Most recent polls</p>
                  <div className="w-full">
                    {/* Your Category Component */}
                    <Category
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                    />
                  </div>
                  {userPolls.map((poll) => (
                    <div
                      key={poll.id}
                      onClick={() => navigate(`/poll/${poll.id}`)}
                      className="cursor-pointer w-full"
                    >
                      {/* Assuming Card is a component, ensure it's styled or accepts props for full width */}
                      <Card className="hover:bg-blue-100 w-full my-4">
                        <div className="flex justify-between">
                          <div className="font-bold truncate">{poll.title}</div>
                          <div className="flex">
                            <CategoryTag category={poll.category} />
                            <PollStatusIndicator
                              isResolved={poll.status === "resolved"}
                              onResultSubmit={() => {}}
                              isPollCreator={false}
                            />
                          </div>
                        </div>
                        <div
                          className="text-gray-600 mb-8 mt-2"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {poll.description}
                        </div>
                        {poll?.predictions?.length ? (
                          <div className="text-gray-600 text-sm">
                            {poll?.predictions?.length}{" "}
                            {`${
                              poll?.predictions?.length === 1
                                ? "prediction"
                                : "predictions"
                            }`}
                          </div>
                        ) : null}
                        <div className="flex justify-between items-end">
                          <div className="text-gray-600 text-sm">
                            Poll by{" "}
                            <span className="font-bold">
                              @{poll.createdByName}
                            </span>{" "}
                            <TimeAgo timestamp={poll.created} />
                          </div>
                          {poll?.predictions?.find(
                            (prediction) =>
                              prediction?.userId ===
                              currentUser?.reloadUserInfo.screenName
                          ) ? (
                            <div className="flex items-center">
                              <p className="hidden md:block mr-2 text-gray-600 text-sm">
                                You have voted
                              </p>
                              <Icon
                                icon={CheckCircleIcon}
                                variant="solid"
                                color="green"
                              />
                            </div>
                          ) : null}
                        </div>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }
        />
        <Route path="/create" element={<CreatePoll user={currentUser} />} />
        <Route path="/poll/:pollId" element={<LivePoll user={currentUser} />} />
      </Routes>
    </div>
  );
}

export default App;
