import React, { useState, useEffect } from "react";

export const Snackbar = ({ message, show, onClose }) => {
  // Automatically close the snackbar after a delay
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000); // Close after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [show, onClose]);

  return (
    <div
      className={`fixed left-1/2 transform -translate-x-1/2 bottom-4 transition-all ${
        show ? "translate-y-0" : "translate-y-20"
      } bg-green-500 text-white px-6 py-3 rounded shadow-lg text-sm`}
      style={{ zIndex: 1000 }}
    >
      {message}
    </div>
  );
};
