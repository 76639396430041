import { BarChart, Card } from "@tremor/react";

export const BarChartExample = ({ data, categories, user }) => {
  const isDistribution = categories[0] === "Number of Predictions";

  const customTooltip = ({ payload, active }) => {
    if (!active || !payload) return null;
    return (
      <div className="rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5">
            <div
              className={`w-1 flex flex-col bg-${category.color}-500 rounded`}
            />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">
                {`${isDistribution ? "Between " : "@"}${
                  category.payload.date
                }: ${category.value}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card className="mx-auto p-0" style={{ borderRadius: "0 0 0px 6px" }}>
      <div className="p-6">
        <BarChart
          className="h-72 mt-4"
          data={data}
          index="date"
          categories={categories}
          // colors={["indigo-300", "rose-200", "#ffcc33"]}
          showXAxis={false}
          customTooltip={customTooltip}
        />
      </div>
    </Card>
  );
};
