import React from "react";

const Button = ({ children, onClick, disabled }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        disabled
          ? "bg-gray-200 text-white font-bold py-2 px-4 rounded !ml-0"
          : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded !ml-0"
      }
    >
      {children}
    </button>
  );
};

export default Button;
