import React from "react";

const Input = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  onKeyPress,
  name,
  multiline,
  rows,
  noMargin,
}) => {
  return (
    <div className={noMargin ? "" : "mb-4"}>
      {label && (
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={name}
        >
          {label}
        </label>
      )}

      {multiline ? (
        <textarea
          id={name}
          name={name}
          rows={rows || 3}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      ) : (
        <input
          id={name}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      )}
    </div>
  );
};

export default Input;
