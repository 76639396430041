import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { signOut, getAuth } from "firebase/auth";
import { SignIn } from "./SignIn";
import { SignOut } from "./SignOut";
import { app } from "../firebaseConfig";
import { CategoryTag } from "./CategoryTag";

export const Header = ({ noNav, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  const pathname = location?.pathname;
  const pathnameIncludesCreate = pathname?.includes("create");

  const logout = () =>
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        console.error("Sign out error:", error);
      });

  const manageSubscription = async () => {
    setIsStripeLoading(true);
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto",
    });
    window.location.assign(data.url);
  };

  return (
    <header className="sticky top-0 z-50 bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a
              href="/"
              className="text-2xl font-bold text-black-500 hover:text-gray-900 flex items-center"
            >
              <svg
                fill="black"
                height="20px"
                width="20px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 490 490"
                transform="matrix(-1, 0, 0, 1, 0, 0)"
                stroke="black"
                style={{ marginRight: 8 }}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <path d="M33.299,245v245l423.402-245L33.299,0V245z M190.579,245l202.992,0L70.27,432.077L190.579,245z"></path>{" "}
                  </g>{" "}
                </g>
              </svg>
              tesla<span className="font-normal">polls</span>
              {/* <CategoryTag category="Beta" isSmall /> */}
            </a>
          </div>
          <nav className="hidden md:flex space-x-10">
            {!noNav && (
              <>
                <a
                  href="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Funktionen
                </a>
                <a
                  href="#"
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Preise
                </a>
              </>
            )}
            {/* Add more navigation items here */}
          </nav>
          {!user ? (
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
              <SignIn />
            </div>
          ) : (
            <>
              <SignOut user={user} />
              {!pathnameIncludesCreate && (
                <div class="relative inline-flex group">
                  <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt mt-4"></div>
                  <a
                    onClick={() => navigate("/create")}
                    href="#"
                    title="Get quote now"
                    class="relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                    role="button"
                  >
                    Create Poll
                  </a>
                </div>
              )}
              {/* <Button variant="danger">Create Poll</Button> */}
            </>
          )}
          {user && (
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className="sr-only">Open menu</span>
                {/* Icon for the hamburger menu */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile menu, show/hide based on mobile menu state */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden`}
      >
        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="px-5 pt-4 flex items-center justify-between">
            <div className="font-black">
              @{user?.reloadUserInfo?.screenName}
            </div>
            <div className="-mr-2">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setIsMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                {/* Icon for closing the menu */}
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="px-2 pt-2 pb-3 space-y-1">
            {/* Mobile menu items */}
            <a
              onClick={manageSubscription}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              {isStripeLoading ? "Loading..." : "Edit Pro Subscription"}
            </a>
            <a
              onClick={logout}
              className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Logout
            </a>
            {/* Add more mobile menu items here */}
          </div>
        </div>
      </div>
    </header>
  );
};
