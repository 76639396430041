import Button from "../components/Button";
import { Dropdown } from "../components/Dropdown";
import Input from "../components/Input";
import useSetState from "../hooks/useSetState";
import { db, auth } from "../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { store } from "../hooks/store";
import { checkout } from "../helpers/createSubscription";
import { Card } from "@tremor/react";

export const CreatePoll = ({ user }) => {
  const userId = auth?.currentUser?.uid;
  const userName = auth?.currentUser?.reloadUserInfo?.screenName;
  const userImage = auth?.currentUser?.photoURL;
  const isProUser = store((state) => state.isProUser);
  const navigate = useNavigate();

  const [
    {
      title,
      description,
      inputType,
      category,
      showIfNotPredicted,
      isAnonymous,
    },
    setState,
  ] = useSetState({
    title: "",
    description: "",
    inputType: "Number",
    category: "Business",
    showIfNotPredicted: false,
    isAnonymous: false,
  });

  const createPoll = async () => {
    try {
      const docRef = await addDoc(collection(db, "polls"), {
        title,
        description,
        inputType,
        category,
        showIfNotPredicted,
        isAnonymous,
        createdBy: userId,
        createdByName: userName,
        createdByImageUrl: userImage,
        created: serverTimestamp(),
      });
      navigate(`/poll/${docRef.id}`);
    } catch (error) {
      console.error("Error creating poll: ", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState({ [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setState({ [name]: checked });
  };

  return (
    <div className="flex flex-row min-h-screen justify-center">
      <div className="p-4 md:p-20">
        <div className="max-w-xl">
          <>
            <Input
              type="text"
              label="Title"
              onChange={handleInputChange}
              name="title"
              value={title}
              placeholder="Give a descriptive title"
            />
            <Input
              type="text"
              label="Description"
              multiline
              onChange={handleInputChange}
              name="description"
              value={description}
              placeholder="Short description if needed"
            />
            <Dropdown
              label="Poll type"
              name="inputType"
              options={["Number"]}
              onChange={handleInputChange}
              helperText="More poll types coming soon (date, text)"
            />
            <Dropdown
              label="Category"
              name="category"
              options={[
                "Business",
                "Stock",
                "Vehicles",
                "Energy",
                "FSD",
                "Competition",
                "Long-term",
                "Other",
              ]}
              onChange={handleInputChange}
            />
          </>

          {/*  <Checkbox
            label="Is Anonymous"
            name="isAnonymous"
            checked={isAnonymous}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            label="Show results even if user hasn't predicted"
            name="showIfNotPredicted"
            checked={showIfNotPredicted}
            onChange={handleCheckboxChange}
          /> */}
          {/*  <Checkbox checked={true} label="Post poll link to X" /> */}

          <Button onClick={createPoll} disabled={!title}>
            Create Poll
          </Button>
        </div>
      </div>
      {/*  <div className="flex-1 p-4 bg-slate-200 p-20">
        <PollPreview
          title={title}
          description={description}
          inputType={inputType}
          showIfNotPredicted={showIfNotPredicted}
        />
      </div> */}
    </div>
  );
};
