import Avatar from "../components/Avatar";
import { ProgressBarWidget } from "./ProgressBar";
import { ResultRankingList } from "./ResultRankingList";
import { UserPredictionsList } from "./UserPredictionsList";

export const PollResults = ({ isResolved, result, user, predictions }) => {
  const calculateMarginOfError = (prediction, result) => {
    return (Math.abs(result - prediction.value) / Math.abs(result)) * 100;
  };

  const userPrediction = predictions.find(
    (x) => x.userId === user?.reloadUserInfo?.screenName
  )?.value;

  const sortedPredictions = predictions
    .map((prediction) => ({
      ...prediction,
      difference: calculateMarginOfError(prediction, result),
    }))
    .sort((a, b) => a.difference - b.difference);

  const userRank =
    userPrediction !== undefined
      ? sortedPredictions.findIndex(
          (pred) => pred.userId === user?.reloadUserInfo?.screenName
        ) + 1
      : null;

  const totalParticipants = predictions.length;

  let marginOfError = null;
  if (userPrediction !== undefined && result !== 0) {
    const errorRate =
      (Math.abs(result - userPrediction) / Math.abs(result)) * 100;
    marginOfError = (100 - errorRate).toFixed(2);
    // Ensure marginOfError is not negative
    marginOfError = Math.max(0, marginOfError);
  }

  return (
    <div className="border border-green-500 border-4 rounded-lg bg-gray-50 h-fit md:mx-32">
      {/* Display the final result if the poll is resolved */}
      {isResolved && (
        <div className="bg-green-100 p-4 rounded-lg text-center">
          <h3 className="text-lg font-bold">Poll Resolved</h3>
          <p className="text-md">Result: {result}</p>
        </div>
      )}
      <div className="flex justify-center items-center p-8 space-x-4">
        <Avatar username="" imageUrl={user?.reloadUserInfo.photoUrl} />
        {userPrediction !== undefined ? (
          <div className="text-center">
            {userRank && (
              <div className="text-sm text-gray-600">
                Rank #{userRank} out of {totalParticipants} predictions
              </div>
            )}
            <div className="font-bold text-lg">
              Your Prediction: {userPrediction}
            </div>
            <div className="text-sm text-gray-600">
              Accuracy Rate: {marginOfError}%
            </div>

            <ProgressBarWidget value={marginOfError} />
          </div>
        ) : (
          <div className="text-sm text-gray-600">
            You didn't take part in this poll
          </div>
        )}
      </div>
      <ResultRankingList predictions={predictions} finalResult={result} />
    </div>
  );
};
