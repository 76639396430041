import React from "react";

const TimeAgo = ({ timestamp }) => {
  if (!timestamp) return;

  const date = new Date(timestamp?.seconds * 1000);
  const now = new Date();
  const secondsAgo = Math.round((now - date) / 1000);
  const minutesAgo = Math.round(secondsAgo / 60);
  const hoursAgo = Math.round(minutesAgo / 60);
  const daysAgo = Math.round(hoursAgo / 24);

  let timeAgoText = "";

  if (daysAgo < 1) {
    if (hoursAgo < 1) {
      if (minutesAgo < 1) {
        timeAgoText = "just now";
      } else {
        timeAgoText = `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
      }
    } else {
      timeAgoText = `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    }
  } else if (daysAgo === 1) {
    timeAgoText = "Yesterday";
  } else {
    timeAgoText = `${daysAgo} days ago`;
  }

  return <span>{timeAgoText}</span>;
};

export default TimeAgo;
