import { AnnounceResults } from "./AnnounceResults";

export const PollStatusIndicator = ({
  isResolved,
  onResultSubmit,
  isPollCreator,
}) => {
  if (isResolved) {
    return (
      <div className="flex items-center">
        <div className="h-3 w-3 bg-red-500 rounded-full"></div>
        {/* <span className="ml-2 text-red-600 text-sm font-semibold">
          Poll Closed
        </span> */}
      </div>
    );
  } else {
    return (
      <div className="flex items-center">
        {isPollCreator && <AnnounceResults onResultSubmit={onResultSubmit} />}
        <div className="h-3 w-3 bg-green-500 rounded-full pulse-dot"></div>
      </div>
    );
  }
};
