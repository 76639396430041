import {
  onSnapshot,
  getFirestore,
  Timestamp,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import "firebase/firestore";
import { useEffect } from "react";
import { app } from "../firebaseConfig";
import useSetState from "../hooks/useSetState";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Avatar from "../components/Avatar";
import { BarChartExample } from "../components/BarChart";
import {
  filterOutliers,
  sortPredictionsByValue,
  createHistogramData,
} from "../helpers/filterHelpers";
import { Toggle } from "../components/Toggle";
import { useSnackbar } from "../hooks/useSnackbar";
import { Snackbar } from "../components/Snackbar";
import { Card, TextInput } from "@tremor/react";
import { Button } from "@tremor/react";
import { Icon } from "@tremor/react";
import { UsersIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Divider } from "@tremor/react";
import { UserPredictionsList } from "../components/UserPredictionsList";
import { PollResults } from "../components/PollResults";
import { PollStatusIndicator } from "../components/PollStatusIndicator";
import TimeAgo from "../components/TimeAgo";
import { Loading } from "../components/Loading";
import { CopyToClipboard } from "../components/CopyToClipboard";
import { store } from "../hooks/store";
import { checkout } from "../helpers/createSubscription";
import {
  randomDetailedPredictions,
  randomPredictions,
} from "../helpers/randomData";

const db = getFirestore(app);

export const LivePoll = ({ user }) => {
  const { snackbar, showSnackbar, closeSnackbar } = useSnackbar();
  const { pollId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isProUser = store((state) => state.isProUser);
  const [
    {
      predictions,
      predictionInput,
      poll,
      displayHistogram,
      hideOutliers,
      loading,
      userHasPredicted,
      checkoutLoading,
      checkoutYearlyLoading,
    },
    setState,
  ] = useSetState({
    predictions: [],
    predictionInput: "",
    poll: null,
    displayHistogram: false,
    hideOutliers: true,
    loading: false,
    userHasPredicted: false,
    checkoutLoading: false,
    checkoutYearlyLoading: false,
  });

  useEffect(() => {
    if (pollId) {
      const unsubscribe = onSnapshot(
        doc(db, "polls", pollId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const pollData = docSnapshot.data();
            const newData = pollData?.predictions?.map((prediction, index) => ({
              ...prediction,
              id: index,
            }));

            // Identify if it's an initial load by checking if there are no predictions in the state yet
            const isInitialLoad = predictions?.length === 0;

            const newPredictions = newData?.filter(
              (newPred) =>
                !predictions?.some(
                  (existingPred) => existingPred.id === newPred.id
                )
            );

            if (newPredictions?.length > 0 && !isInitialLoad) {
              newPredictions.forEach((prediction) => {
                showSnackbar(
                  `🎉 New prediction added from @${prediction.userId} ${
                    isProUser ? prediction.value : ""
                  }`
                );
              });
            }

            // Update the state to include the new predictions whether it's the initial load or not
            setState({
              predictions: newData,
              userHasPredicted: newData?.some(
                (prediction) =>
                  prediction.userId === user?.reloadUserInfo?.screenName
              ),
            });
          }
        }
      );

      return () => unsubscribe();
    }
  }, [pollId, showSnackbar, user?.reloadUserInfo?.screenName]);

  useEffect(() => {
    if (pollId) {
      setState({ loading: true });
      const pollRef = doc(db, "polls", pollId);
      const unsubscribe = onSnapshot(pollRef, (doc) => {
        if (doc.exists()) {
          const pollData = doc.data();
          setState({ poll: pollData, loading: false });
        } else {
          setState({ loading: false });
        }
      });
      return () => unsubscribe();
    }
  }, [pollId]);

  const isResolved = poll?.status === "resolved";
  const finalResult = poll?.finalResult;

  const toggleOutliers = () => {
    setState({ hideOutliers: !hideOutliers });
  };

  const toggleHistogram = () => {
    setState({ displayHistogram: !displayHistogram });
  };

  const submitPrediction = async (pollId, predictionValue) => {
    if (!pollId || predictionValue === undefined) {
      console.error("Poll ID or Prediction Value is undefined");
      return;
    }
    try {
      const pollRef = doc(db, "polls", pollId);

      // Using Firebase Firestore to generate a timestamp on the client side
      // This is less accurate than serverTimestamp but necessary in this context
      const timestamp = Timestamp.now();

      await updateDoc(pollRef, {
        predictions: arrayUnion({
          value: predictionValue,
          userId: user?.reloadUserInfo?.screenName,
          userPhoto: user?.reloadUserInfo?.photoUrl,
          // Replace serverTimestamp() with a client-generated timestamp
          timestamp: timestamp,
        }),
      });
    } catch (error) {
      console.error("Error appending prediction: ", error);
    }
  };

  const handlePredictionSubmit = () => {
    submitPrediction(pollId, predictionInput);
    setState({ predictionInput: "" });
  };

  const processedData = hideOutliers
    ? sortPredictionsByValue(filterOutliers(predictions))
    : sortPredictionsByValue(predictions);

  const histoData = createHistogramData(predictions);
  const isPollCreator = poll?.createdBy === user?.uid;

  const handleResultSubmit = async (result) => {
    if (poll.createdBy === user?.uid) {
      try {
        await updateDoc(doc(db, "polls", pollId), {
          finalResult: result,
          status: "resolved",
        });
      } catch (error) {
        console.error("Error submitting poll result:", error);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="w-full flex justify-center mt-2 md:mt-8 cursor-pointer items-center gap-2">
        <div onClick={() => navigate("/")} className="flex items-center gap-2">
          <Icon
            icon={ArrowLeftIcon}
            variant="outlined"
            onClick={() => navigate("/")}
          />{" "}
          <p>Go Back</p>
        </div>
        <CopyToClipboard url={`teslapolls.com${location.pathname}`} />
      </div>
      <div className="p-2 md:p-10 h-screen flex justify-center grid-background gap-4">
        <div className="w-full md:w-1/2 border border-gray-200 rounded md:rounded-lg bg-gray-50 h-fit">
          <div className="bg-gray-50 rounded-lg p-4 md:p-8">
            {/* Flex container for Avatar and Live Poll indicator */}
            <div className="flex justify-between items-start mb-4">
              {/* Left side - Avatar and user details */}
              <div className="flex items-center">
                <Avatar username="" imageUrl={poll?.createdByImageUrl} />
                <div className="leading-5 ml-2">
                  <span className="font-bold">Poll</span> by{" "}
                  <span className="font-bold">@{poll?.createdByName}</span>
                  <div>
                    <span className="text-slate-500 text-sm font-semibold">
                      <TimeAgo timestamp={poll?.created} />
                    </span>
                  </div>
                </div>
              </div>

              {/* Right side - Live Poll indicator */}
              <PollStatusIndicator
                isResolved={isResolved}
                onResultSubmit={handleResultSubmit}
                isPollCreator={isPollCreator}
              />
            </div>

            {/* Poll Title and Description */}
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              {poll?.title}
            </h2>
            <p className="text-gray-600 mb-8">{poll?.description}</p>

            {!isResolved && (
              <div className="bg-gray-50 rounded-lg ">
                {/* Prediction Input and Button */}
                {!userHasPredicted && (
                  <TextInput
                    placeholder="Enter prediction"
                    type="number"
                    value={predictionInput}
                    onChange={(e) =>
                      setState({ predictionInput: e.target.value })
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && predictionInput) {
                        handlePredictionSubmit();
                      }
                    }}
                    disabled={isResolved || userHasPredicted}
                  />
                )}
                <Button
                  onClick={handlePredictionSubmit}
                  className="mt-2"
                  disabled={isResolved || userHasPredicted || !predictionInput}
                >
                  {userHasPredicted
                    ? "You have already predicted!"
                    : `Submit as @${user?.reloadUserInfo?.screenName}`}
                </Button>
              </div>
            )}
          </div>
          {isResolved && (
            <PollResults
              isResolved={isResolved}
              result={finalResult}
              user={user}
              predictions={predictions}
            />
          )}

          {(predictions?.length && userHasPredicted) || isResolved ? (
            <>
              <Divider>Predictions</Divider>
              <div className="flex justify-between items-end md:items-center mb-6 gap-4 px-8">
                <div className="block md:flex gap-2 items-center">
                  <Icon icon={UsersIcon} variant="solid" />
                  <div>
                    {predictions?.length}{" "}
                    {predictions?.length === 1 ? "Prediction" : "Predictions"}
                  </div>
                </div>
                <div className="flex gap-4">
                  <Toggle
                    checked={displayHistogram}
                    onChange={toggleHistogram}
                    label="Prediction Distribution"
                  />
                  <Toggle
                    checked={hideOutliers}
                    onChange={toggleOutliers}
                    label="Hide Outliers"
                  />
                </div>
              </div>
              {!isProUser && (
                <div
                  className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  style={{ marginTop: 200, zIndex: 100 }}
                >
                  <Card style={{ maxWidth: 500 }}>
                    <p className="font-bold">Go Pro!</p>
                    <p className="text-gray-600">
                      Gain instant access to real-time poll insights, revealing
                      who predicted what before the poll is resolved.
                    </p>
                    <div class="relative inline-flex  group mt-4">
                      <div class="absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt mt-4"></div>
                      <a
                        onClick={() => {
                          if (checkoutLoading) return;
                          setState({ checkoutLoading: true });
                          checkout(user, "price_1P3gDbGsM1FqHX86unkmLeuZ");
                        }}
                        class={
                          checkoutLoading
                            ? "relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-600 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                            : "relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                        }
                        role="button"
                      >
                        {checkoutLoading ? "Loading..." : "Go Pro $7 / month"}
                      </a>

                      <a
                        onClick={() => {
                          if (checkoutYearlyLoading) return;
                          setState({ checkoutYearlyLoading: true });
                          checkout(user, "price_1P3gDhGsM1FqHX86niZAUdHP");
                        }}
                        class={
                          checkoutYearlyLoading
                            ? "relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-600 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ml-2"
                            : "relative inline-flex items-center justify-center px-4 py-2 text-md font-bold text-white transition-all duration-200 bg-gray-900 font-pj rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ml-2"
                        }
                        role="button"
                      >
                        {checkoutYearlyLoading
                          ? "Loading..."
                          : "Go Pro $69 / year"}
                      </a>
                    </div>
                    <div className="text-xs text-gray-500 mt-4">
                      - Cancel anytime
                    </div>
                    <div className="text-xs text-gray-500">
                      - Continuous development
                    </div>
                    <div className="text-xs text-gray-500">
                      - Support solo dev @mrShomski
                    </div>
                  </Card>
                </div>
              )}
              <div className={`${isProUser ? "" : "blur"}`}>
                {isProUser ? (
                  <>
                    <BarChartExample
                      user={user}
                      categories={
                        displayHistogram
                          ? ["Number of Predictions"]
                          : ["Prediction"]
                      }
                      data={
                        displayHistogram
                          ? histoData
                          : processedData?.map((x) => ({
                              date: x.userId,
                              Prediction: Number(x.value),
                            }))
                      }
                    />
                    <UserPredictionsList predictions={predictions} />
                  </>
                ) : (
                  <>
                    <BarChartExample
                      user={user}
                      categories={
                        displayHistogram
                          ? ["Number of Predictions"]
                          : ["Prediction"]
                      }
                      data={randomPredictions}
                    />
                    <UserPredictionsList
                      predictions={randomDetailedPredictions}
                    />
                  </>
                )}
              </div>
            </>
          ) : null}
        </div>

        <Snackbar
          show={snackbar.show}
          message={snackbar.message}
          onClose={closeSnackbar}
        />
      </div>
    </>
  );
};
