import { collection, onSnapshot, addDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const checkout = async (currentUser, price) => {
  const docRef = await addDoc(
    collection(db, `customers/${currentUser.uid}/checkout_sessions`),
    {
      price,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      allow_promotion_codes: false,
    }
  );
  onSnapshot(docRef, (snap) => {
    const { error, url } = snap.data();
    if (error) {
      alert(`An error occured: ${error.message}`);
    }
    if (url) {
      window.location.assign(url);
    }
  });
};
