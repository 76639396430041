import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAry9N31LrMfPN5ndNY-x7tLcTi09SSx_E",
  authDomain: "tesla-zero.firebaseapp.com",
  projectId: "tesla-zero",
  storageBucket: "tesla-zero.appspot.com",
  messagingSenderId: "240048644107",
  appId: "1:240048644107:web:5fc3ceedc02204c760670e",
  measurementId: "G-YMK547N60M",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
