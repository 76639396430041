import { BrowserFrame } from "../components/BrowserFrame";
import { Header } from "../components/Header";
import { SignIn } from "../components/SignIn";
import {
  getAuth,
  signInWithRedirect,
  TwitterAuthProvider,
} from "firebase/auth";
import { useRef, useEffect } from "react";
import video from "../teslapulse.mp4";
import { Features } from "../components/Features";

export const Landing = ({ user }) => {
  const videoRef = useRef(null);
  const provider = new TwitterAuthProvider();
  const auth = getAuth();
  const signIn = () =>
    signInWithRedirect(auth, provider)
      .then((result) => {
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;
        const user = result.user;
      })
      .catch((error) => {
        console.error(error);
      });

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2;
    }
  }, []);

  return (
    <>
      <Header noNav user={user} />
      <div className="font-sans text-gray-900 antialiased w-full flex flex-col items-center  px-8">
        {/* Hero Section */}
        <div className="text-black text-center py-10 md:py-20 overflow-hidden">
          <div style={{ maxWidth: 600 }}>
            <div className="flex gap-4 justify-center text-gray-500">
              <p className="mb-6">Vehicles</p>
              <p className="mb-6">Energy</p>
              <p className="mb-6">Business</p>
              <p className="mb-6">Stock</p>
            </div>
            <h1 className="text-4xl md:text-6xl font-black text-gray-800 mb-4 animate-fadeInUp">
              Tesla polls from <br />X / Twitter Users
            </h1>

            <p className="text-l md:text-xl mb-8 animate-fadeInUp animation-delay-200 text-gray-700">
              Create and participate in real-time polls, track your predictions
              over time, and see how you stack up against the Tesla community!
            </p>
          </div>
          <SignIn />
        </div>

        <div className="flex w-full items-center justify-center">
          <video
            className="lg:w-2/3 sm:w-full"
            ref={videoRef}
            src={video}
            style={{
              borderRadius: 20,
              marginBottom: 64,
              boxShadow:
                "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}
            autoPlay
            muted
            loop
            playsInline
          />
        </div>
        <Features />

        {/* Footer */}
        {/* <div className="bg-gray-800 text-gray-400 py-8">
          <div className="max-w-6xl mx-auto px-6 text-center">
            &copy; 2024 Better Twitter Polls. All rights reserved.
          </div>
        </div> */}
      </div>
    </>
  );
};
