import { useReducer } from "react";

function init(initialState) {
  return { ...initialState };
}

const useSetState = (initialState) => {
  const [state, setState] = useReducer(
    (currentState, newState) => ({
      ...currentState,
      ...newState,
    }),
    initialState,
    init
  );

  return [state, setState];
};

export default useSetState;
