import result from "../images/result.png";
import categories from "../images/categories.png";
import consensus from "../images/consensus.png";

const featuresData = [
  {
    id: "feature1",
    title:
      "Take part in polls and see your performance against the tesla community",
    description:
      "Uncover your stance on future predictions, measure your insights against the broader community, and establish your own track record!",
    image: result,
  },
  {
    id: "feature2",
    title: "Create categorized Polls",
    description:
      "Business, Stock, Vehicles, Energy, FSD, Competition, Long-term, Other -> Easily search exisiting or create new polls based on categories",
    image: categories,
  },
  {
    id: "feature3",
    title: "View community consesus for specific topics",
    description:
      "Overview of the community as a whole and compare your predictions",
    image: consensus,
  },
];

const Feature = ({ title, description, image, isRightAligned, type }) => {
  const textClass = isRightAligned ? "lg:col-start-1" : "lg:col-start-7";
  const imgClass = isRightAligned
    ? "lg:col-start-7 lg:row-start-1"
    : "lg:col-start-1 lg:row-start-1";

  return (
    <div className="flex flex-wrap lg:grid lg:grid-cols-12 lg:gap-16 lg:items-center mb-16">
      <div className={`${textClass} lg:col-span-5 mb-10 lg:mb-0`}>
        <h2 className="text-xl font-black text-gray-800 ">{title}</h2>
        <p className="mt-4 text-gray-600 dark:text-gray-400">{description}</p>
      </div>
      <div className={`${imgClass} lg:col-span-6`}>
        <img
          src={image}
          className="rounded-xl shadow-xl shadow-gray-200 dark:shadow-gray-900/[.2] max-w-full"
        />
      </div>
    </div>
  );
};

export const Features = () => {
  return (
    <div className="max-w-[65rem] mx-auto" id="features">
      <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14 ">
        <h2 className="text-xl font-black text-gray-800 md:text-4xl md:leading-tight">
          Why should you join?
        </h2>
      </div>
      {featuresData.map((feature, index) => (
        <Feature
          key={feature.id}
          title={feature.title}
          description={feature.description}
          image={feature.image}
          isRightAligned={index % 2 === 0}
          type={feature?.type}
        />
      ))}
    </div>
  );
};
