export const Toggle = ({ checked, onChange, label }) => {
  return (
    <label className="block md:flex items-center cursor-pointer">
      <div className="relative">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className="sr-only"
        />
        {/* Adjust the size of the toggle background */}
        <div
          className={`block ${
            checked ? "bg-blue-500" : "bg-gray-400"
          } w-10 h-5 rounded-full transition-colors duration-300`}
        ></div>
        {/* Adjust the size and position of the toggle circle */}
        <div
          className={`absolute left-0.5 top-0.5 bg-white w-4 h-4 rounded-full transition-transform duration-300 ${
            checked ? "transform translate-x-5" : ""
          }`}
        ></div>
      </div>
      <span className="ml-2 text-gray-700 font-medium">{label}</span>
    </label>
  );
};
