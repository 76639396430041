import { Select, SelectItem } from "@tremor/react";

export const Category = ({ handleCategoryChange, selectedCategory }) => {
  return (
    <Select
      defaultValue="all"
      onChange={(value) => handleCategoryChange(value)}
      enableClear={false}
      value={selectedCategory}
    >
      <SelectItem value="all">All Categories</SelectItem>
      <SelectItem value="Business">Business</SelectItem>
      <SelectItem value="Stock">Stock</SelectItem>
      <SelectItem value="Vehicles">Vehicles</SelectItem>
      <SelectItem value="Energy">Energy</SelectItem>
      <SelectItem value="FSD">FSD</SelectItem>
      <SelectItem value="Competition">Competition</SelectItem>
      <SelectItem value="Long-term">Long-term</SelectItem>
      <SelectItem value="Other">Other</SelectItem>
    </Select>
  );
};
