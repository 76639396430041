import React, { Fragment, useState } from "react";
import { Button, Card, Flex, Text, TextInput, BarList } from "@tremor/react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export const ResultRankingList = ({ predictions, finalResult }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Function to calculate the difference from the final result
  const calculateDifference = (prediction) =>
    Math.abs(prediction.value - finalResult);

  // Sort predictions based on their closeness to the final result
  const sortedPredictions = predictions
    .map((prediction) => ({
      ...prediction,
      difference: calculateDifference(prediction),
    }))
    .sort((a, b) => a.difference - b.difference)
    .map((prediction, index) => ({
      ...prediction,
      name: `#${index + 1} - @${prediction.userId}`,
    }));

  const filteredSortedPredictions = sortedPredictions.filter((prediction) =>
    prediction.userId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  return (
    <>
      <Card className="mx-auto">
        <Flex alignItems="center" justifyContent="between">
          <Text className="text-base text-gray-700 font-medium">
            Leaderboard
          </Text>
        </Flex>
        <BarList
          data={sortedPredictions.slice(0, 5)}
          className="mt-8"
          showAnimation={false}
          valueFormatter={valueFormatter}
          color="green"
        />
        <Button
          icon={ArrowsPointingOutIcon}
          className="mt-4 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
          onClick={openModal}
        >
          Show all Rankings
        </Button>
      </Card>

      {/* Dialog for showing all rankings */}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <div className="fixed inset-0 bg-gray-900 bg-opacity-90" />
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-tremor transition-all">
                <Flex alignItems="center" justifyContent="between">
                  <Text className="text-base text-gray-700 font-medium">
                    Leaderboard
                  </Text>
                </Flex>
                <TextInput
                  icon={MagnifyingGlassIcon}
                  placeholder="Search..."
                  className="mt-6"
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <div className="relative mt-4 h-[450px] overflow-y-scroll">
                  <BarList
                    data={filteredSortedPredictions}
                    className="mr-4"
                    showAnimation={false}
                    valueFormatter={valueFormatter}
                    color="green"
                  />
                </div>
                <Button
                  className="mt-2 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
                  onClick={closeModal}
                >
                  Go back
                </Button>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
