const calculateMean = (data) => {
  if (!data) return;
  const sum = data.reduce((acc, value) => acc + value, 0);
  return sum / data.length;
};

const calculateStandardDeviation = (data, mean) => {
  if (!data) return;
  const squareDiffs = data.map((value) => {
    const diff = value - mean;
    return diff * diff;
  });
  const avgSquareDiff = calculateMean(squareDiffs);
  return Math.sqrt(avgSquareDiff);
};

export const filterOutliers = (data) => {
  if (!data) return;
  const values = data?.map((x) => Number(x?.value));
  const mean = calculateMean(values);
  const standardDeviation = calculateStandardDeviation(values, mean);
  const threshold = 2;

  return data?.filter((x) => {
    const deviation = Math.abs(Number(x.value) - mean);
    return deviation <= threshold * standardDeviation;
  });
};

export const sortPredictionsByValue = (data, direction = "asc") => {
  if (!data) return;
  return [...data].sort((a, b) => {
    if (direction === "asc") {
      return Number(a.value) - Number(b.value);
    } else {
      return Number(b.value) - Number(a.value);
    }
  });
};

const calculateIQR = (values) => {
  const sortedValues = [...values].sort((a, b) => a - b);
  const q1 = sortedValues[Math.floor(sortedValues.length / 4)];
  const q3 = sortedValues[Math.floor((3 * sortedValues.length) / 4)];
  return q3 - q1;
};

const calculateBinSize = (values) => {
  const iqr = calculateIQR(values);
  const n = values.length;
  const binSize = 2 * (iqr / Math.cbrt(n));
  return Math.max(1, Math.round(binSize)); // Ensure a minimum bin size of 1
};

// Helper function to create bins
const createBins = (min, max, binSize) => {
  const bins = [];
  for (let i = min; i <= max; i += binSize) {
    bins.push({ min: i, max: i + binSize, count: 0 });
  }
  return bins;
};

// Function to convert prediction data into histogram data
export const createHistogramData = (predictions) => {
  if (!predictions || !predictions.length) {
    return [];
  }

  // Determine the range of your data
  const values = predictions.map((p) => Number(p.value));
  const binSize = calculateBinSize(values);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  // Create bins
  const bins = createBins(minValue, maxValue, binSize);

  // Count frequencies per bin
  predictions.forEach((prediction) => {
    const value = Number(prediction.value);
    const binIndex = Math.floor((value - minValue) / binSize);
    if (bins[binIndex]) {
      bins[binIndex].count += 1;
    }
  });

  // Filter out empty bins and format for the chart
  const histogramData = bins
    .filter((bin) => bin.count > 0) // Filter out bins with zero count
    .map((bin) => ({
      date: `${bin.min}-${bin.max}`,
      "Number of Predictions": bin.count,
    }));

  return histogramData;
};
