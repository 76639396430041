import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signOut } from "firebase/auth";
import Avatar from "./Avatar";
import { Badge } from "@tremor/react";
import { store } from "../hooks/store";
import { app } from "../firebaseConfig";

export const SignOut = ({ user }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const isProUser = store((state) => state.isProUser);
  const auth = getAuth();

  const logout = () =>
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
        console.error("Sign out error:", error);
      });

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const manageSubscription = async () => {
    setIsStripeLoading(true);
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      returnUrl: window.location.origin,
      locale: "auto",
    });
    window.location.assign(data.url);
  };

  return (
    <div
      className="invisible md:visible"
      style={{ display: "inline-block", position: "relative" }}
    >
      <div
        onClick={toggleDropdown}
        style={{ cursor: "pointer" }}
        className="flex items-center"
      >
        <Avatar
          username={user?.reloadUserInfo?.screenName}
          imageUrl={user?.reloadUserInfo?.photoUrl}
        />
        {isProUser && <Badge className="font-bold">Pro</Badge>}
      </div>
      {showDropdown && (
        <div
          style={{
            position: "absolute",
            top: "100%", // Position below the avatar
            left: 0,
            background: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "8px",
          }}
        >
          {/* Each menu item can be a div or a link (<a> or <Link> if using React Router) */}

          {/* Logout has been moved into the menu */}
          <div style={{ padding: "4px", cursor: "pointer" }} onClick={logout}>
            Sign Out
          </div>
          {isProUser && (
            <div
              style={{ padding: "4px", cursor: "pointer" }}
              onClick={manageSubscription}
            >
              {isStripeLoading ? "Loading..." : "Edit Pro Subscription"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
