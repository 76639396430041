import { useState, useCallback } from "react";

// useSnackbar.js
export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });

  const showSnackbar = useCallback((message) => {
    setSnackbar({ show: true, message });
    // Hide the snackbar after 3 seconds
    setTimeout(() => {
      setSnackbar({ show: false, message: "" });
    }, 3000);
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbar({ show: false, message: "" });
  }, []);

  return { snackbar, showSnackbar, closeSnackbar };
};
