import { Fragment, useState, useEffect } from "react";
import { Button, Card, Flex, Text, TextInput, BarList } from "@tremor/react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { store } from "../hooks/store";

const valueFormatter = (number) =>
  `${Intl.NumberFormat("us").format(number).toString()}`;

export const UserPredictionsList = ({ predictions }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedPredictions, setSortedPredictions] = useState([]);
  const [renderKey, setRenderKey] = useState(0);
  const isProUser = store((state) => state.isProUser);

  useEffect(() => {
    // Creating a deep copy of the predictions array
    const predictionsCopy = predictions.map((pred) => ({ ...pred }));

    const sorted = predictionsCopy
      .sort((a, b) => {
        // Check for null timestamps
        if (!a.timestamp) return -1;
        if (!b.timestamp) return 1;

        // Otherwise, sort by timestamp
        return b.timestamp.seconds - a.timestamp.seconds;
      })
      .map((prediction, index) => ({
        ...prediction,
        name: isProUser ? `@${prediction.userId}` : "Go Pro to find out!",
      }));

    setSortedPredictions(sorted);
    setRenderKey((prevKey) => prevKey + 1);
  }, [predictions]);

  const filteredpages = sortedPredictions.filter((item) =>
    item.userId?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return (
    <div key={renderKey} className="w-full">
      <Card className="mx-auto  h-full" style={{ borderRadius: "0 0 4px 0" }}>
        <Flex alignItems="center" justifyContent="between">
          <Text className="text-base text-gray-700 font-medium">
            Most recent predictions
          </Text>
          <Text className="uppercase"></Text>
        </Flex>
        <BarList
          data={sortedPredictions.slice(0, 5)}
          className="mt-8"
          showAnimation={false}
          valueFormatter={valueFormatter}
        />
        <Button
          icon={ArrowsPointingOutIcon}
          className="mt-4 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
          onClick={openModal}
        >
          Show all
        </Button>
      </Card>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-90" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full max-w-xl transform overflow-hidden ring-tremor bg-white
                                    p-6 text-left align-middle shadow-tremor transition-all rounded-xl"
                >
                  <Flex alignItems="center" justifyContent="between">
                    <Text className="text-base text-gray-700 font-medium">
                      User Predictions
                    </Text>
                    <Text className="uppercase"></Text>
                  </Flex>
                  <TextInput
                    icon={MagnifyingGlassIcon}
                    placeholder="Search..."
                    className="mt-6"
                    onChange={(event) => setSearchQuery(event.target.value)}
                  />
                  <div className="relative mt-4 h-[450px] overflow-y-scroll">
                    <BarList
                      data={filteredpages}
                      className="mr-4" // to give room for scrollbar
                      showAnimation={false}
                      valueFormatter={valueFormatter}
                    />
                    <div className="sticky inset-x-0 bottom-0 p-6 bg-gradient-to-t from-white to-transparent h-20" />
                  </div>
                  <Button
                    className="mt-2 w-full bg-white border-gray-200 text-gray-500 hover:bg-gray-50 hover:border-gray-300"
                    onClick={closeModal}
                  >
                    Go back
                  </Button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
