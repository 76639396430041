export const CategoryTag = ({ category, isSmall = false }) => {
  const categoryColorMap = {
    Business: "text-blue-500 bg-blue-100",
    Stock: "text-green-500 bg-green-100",
    Vehicles: "text-orange-500 bg-red-100",
    Energy: "text-yellow-500 bg-yellow-100",
    FSD: "text-lime-500 bg-lime-100",
    Competition: "text-purple-500 bg-purple-100",
    "Long-term": "text-pink-500 bg-pink-100",
    Other: "text-gray-500 bg-gray-100",
  };

  const colorClasses =
    categoryColorMap[category] || "text-blue-500 bg-blue-100";

  return (
    <span
      className={
        isSmall
          ? `ml-2 inline-flex items-center gap-x-1 rounded-tremor-small px-1 py-0 text-tremor-label font-xs font-semibold ${colorClasses} `
          : `mr-2 inline-flex items-center gap-x-1 rounded-tremor-small px-2 py-1 text-tremor-label font-semibold ${colorClasses} `
      }
    >
      {category}
    </span>
  );
};
