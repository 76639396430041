import React, { useState } from "react";
import { ClipboardIcon, CheckCircleIcon } from "@heroicons/react/24/solid";

export const CopyToClipboard = ({ url }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div
      className="flex items-center bg-gray-100 px-2 rounded-lg cursor-pointer"
      onClick={handleCopy}
    >
      <input
        type="text"
        readOnly
        value={url}
        className="bg-transparent border-none focus:ring-0 w-full text-gray-800 cursor-pointer text-xs"
        onClick={handleCopy}
      />
      <button onClick={handleCopy} className="p-2 rounded-md hover:bg-gray-200">
        {copied ? (
          <CheckCircleIcon className="h-5 w-5 text-green-600" />
        ) : (
          <ClipboardIcon className="h-5 w-5 text-gray-600" />
        )}
      </button>
    </div>
  );
};
